'use client';

import { Box } from '@chakra-ui/react';
import type { Property as CSSProperty } from 'csstype';
import { Dispatch, SetStateAction, useState } from 'react';
import { ResizableBox } from 'react-resizable';

import { Table, TableContainer } from '@/pages/common';
import { ListSettingResponse } from '@/schemas/ListSetting.schema';

import { TableData } from './TableData';

type Props = {
  data?: ListSettingResponse;
  isTableLoading?: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  maxHeight?: CSSProperty.MaxHeight;
};

const TABLE_PADDING = 24;
const TABLE_COLUMN_WIDTHS = [135, 155, 195, 180, 120, 120, 135, 160, 160].map(
  (i) => i - TABLE_PADDING,
);

const ResizableTh = ({
  width,
  onResize,
  children,
  minConstraints,
  className,
}) => (
  <ResizableBox
    width={width}
    height={15}
    axis="x"
    resizeHandles={['e']}
    handle={<div className={className}>|</div>}
    onResize={(_, { size }) => onResize(size.width)}
    style={{ display: 'flex' }}
    minConstraints={minConstraints}
  >
    <Box display="inline-block" width={width}>
      {children}
    </Box>
  </ResizableBox>
);

export const TableContents = (props: Props) => {
  const { data, isTableLoading, page, setPage, totalPages, maxHeight } = props;

  const [columnWidths, setColumnWidths] = useState([...TABLE_COLUMN_WIDTHS]);

  const handleResize = (index, newWidth) => {
    const newWidths = [...columnWidths];
    newWidths[index] = newWidth;
    setColumnWidths(newWidths);
  };

  return (
    <>
      <TableContainer
        tableContainerProps={{
          flexGrow: 1,
          maxHeight,
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.Heading
              width={`${columnWidths[0]}px`}
              className="stickyColumnLeft"
            >
              <ResizableTh
                width={columnWidths[0]}
                onResize={(newWidth) => handleResize(0, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[0], Infinity]}
                className="customResizeHandler"
              >
                ログインURL
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[1]}px`}>
              <ResizableTh
                width={columnWidths[1]}
                onResize={(newWidth) => handleResize(1, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[1], Infinity]}
                className="customResizeHandler"
              >
                同意時遷移先URL
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[2]}px`}>
              <ResizableTh
                width={columnWidths[2]}
                onResize={(newWidth) => handleResize(2, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[2], Infinity]}
                className="customResizeHandler"
              >
                キャンセル時遷移先URL
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[3]}px`}>
              <ResizableTh
                width={columnWidths[3]}
                onResize={(newWidth) => handleResize(3, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[3], Infinity]}
                className="customResizeHandler"
              >
                ブロック時遷移先URL
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[4]}px`}>
              <ResizableTh
                width={columnWidths[4]}
                onResize={(newWidth) => handleResize(4, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[4], Infinity]}
                className="customResizeHandler"
              >
                友だち追加
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[5]}px`}>
              <ResizableTh
                width={columnWidths[5]}
                onResize={(newWidth) => handleResize(5, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[5], Infinity]}
                className="customResizeHandler"
              >
                状態
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[6]}px`}>
              <ResizableTh
                width={columnWidths[6]}
                onResize={(newWidth) => handleResize(6, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[6], Infinity]}
                className="customResizeHandler"
              >
                備考
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[7]}px`}>
              <ResizableTh
                width={columnWidths[7]}
                onResize={(newWidth) => handleResize(7, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[7], Infinity]}
                className="customResizeHandler"
              >
                登録日時
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={`${columnWidths[8]}px`}>
              <ResizableTh
                width={columnWidths[8]}
                onResize={(newWidth) => handleResize(8, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[8], Infinity]}
                className="customResizeHandler"
              >
                更新日時
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={80} className="stickyColumnRight" />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isTableLoading && <Table.Progress colSpan={10} />}
          {data?.records.map((record) => (
            <TableData key={record.id} record={record} />
          ))}
        </Table.Body>
      </TableContainer>
      <Table.Pagination
        currentPage={page}
        totalPages={totalPages}
        totalRecords={data?.total ?? 0}
        onPageChange={setPage}
      />
    </>
  );
};
